import styled from '@emotion/styled';
import React, { useEffect, useState, useRef } from 'react';

import AnalysisImage from './AnalysisImage';
import AnalysisVideo from './AnalysisVideo';

import { Card, Heading, Button } from '../../common';
import copy_active from '../../images/copy_active.svg';
import copy from '../../images/copy.svg';
import AnalysisAudio from './AnalysisAudio';
import SummaryTextContainer from '../../common/SummaryTextContainer';
import loading from '../../assets/images/loading.svg';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_CHECK_SUMMARY } from '../../constants/analysis/checkSummaryConstants';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { REQUEST_GENERATE_SUMMARY_DATA } from '../../constants/analysis/generateSummaryConstants';
import { ActionIcon, Icon } from '../../common';
import { Checkbox, InputText, Radio, TextArea, Switch } from '../../components/form';
//import { REQUEST_SUMMARYDATA } from '../constants/field/GetSummaryDataConstant';
import { REQUEST_SUMMARYDATA, EDIT_SUMMARYDATA } from '../../constants/field/GetSummaryDataConstant';
const SeeButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  line-height: 1;

  .reverse {
    transform: rotate(-180deg);
  }
`;
const CustomTextArea = styled(TextArea)`
  ::placeholder,
  ::-webkit-input-placeholder,
  ::-moz-placeholder,
  :-ms-input-placeholder {
    color: var(--grey20);
    font-weight: 400;
    font-size: 10px;
  }
  .characters {
    display: none !important;
  }
`;
const CustomButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: var(--midWhite);
  color: var(--sapGreen);
  border-radius: 3px;
  padding: 0.4rem 0.9rem;
  img {
    padding-right: 5px;
  }
  :hover {
    background: var(--midWhite);
    color: ${props => props.buttonColor};
  }

  :disabled,
  [disabled] {
    background: var(--midWhite);
    border: none;
    color: var(--middleGrey);

    :hover {
      background: var(--midWhite);
      border: none;
      color: var(--middleGrey);
      cursor: not-allowed;
    }
  }
`;

const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  justify-content: space-between;

  // For screens smaller than or equal to 1350px
  @media (max-width: 1350px) {
    grid-template-columns: repeat(4, 1fr);
  }

  // For screens larger than 1350px
  @media (min-width: 1351px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

function AnalysisQuestion({
  questionObject,
  question,
  information,
  onCopyClick,
  isSelectAll = true,
  type = 'image',
  handleCopyClick,
  data,
  tag,
  description,
  note,
  qid,
  isMp4 = false,
  cTag,
  vType,
  isSummary,
  preAnalysisData,
  preSelectedGenerateSummary,
  summaryWithQid,
  isLoadingView,
  isLoadingVideo,
  setIsLoadingView,
  interviewsCompleted,
  isLoadingAudio,
  isHitsOn
}) {
  const [selectedItems, setSelectedItems] = useState(false);
  const [addReport, setAddReport] = useState({});
  const [selectedGenerateSummary, setSelectedGenerateSummary] = useState([]);
  // setSelectedGenerateSummary([...selectedGenerateSummary, preSelectedGenerateSummary]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  let { id } = useParams();
  const loggedUser = useSelector(state => state.LoginReducer);

  const [summaryData, setSummaryData] = useState('');
  const [seeMorePrimary, setSeeMorePrimary] = useState(false);
  // var summaryData = ''
  const summaryData2 = useSelector(state => state.getSummaryReducer.Analysisdata);
  const [summary, setSummary] = useState('');
  const [sliceSummary, setSliceSummary] = useState('');

  const [isEditing, setIsEditing] = useState(false); // Define isEditing state
  const [editedSummary, setEditedSummary] = useState(summary);

  const [showMore, setShowMore] = useState(false);
  const [currentPlaying, setCurrentPlaying] = useState(null);

  const handlePlay = (index, playerRef) => {
    // Pause the previous video if it's different from the current
    if (currentPlaying !== null && currentPlaying !== playerRef) {
      currentPlaying.load(); // pause the previous video
    }

    setCurrentPlaying(playerRef); // Set the currently playing video ref
  };

  const SeeLess = () => <>Show Less</>;
  const SeeMore = () => <>Show More</>;

  var mp3Flag = 0;

  const handleSave = () => {
    //console.log("Ediedsummary" ,editedSummary)
    dispatch({
      type: EDIT_SUMMARYDATA,
      payload: {
        user_ID: loggedUser.userId,
        project_ID: id,
        question_ID: qid,
        analysis: editedSummary
      }
    });
    setSummary(editedSummary);
    dispatch({
      type: REQUEST_SUMMARYDATA,
      payload: {
        project_ID: id,
        question_ID: qid
      }
    });

    setSeeMorePrimary(false);

    setIsEditing(false);
  };

  if (questionObject?.answer?.length === 1) {
    if (
      questionObject?.answer[0]?.MP3UpdateSummaryStatus == '1' ||
      questionObject?.answer[0]?.MP3UpdateSummaryStatus === null
    ) {
      mp3Flag = 1;
    }
  } else {
    for (var i = 0; i < questionObject?.answer?.length; i++) {
      if (questionObject?.answer[i]?.MP3UpdateSummaryStatus == '1') {
        mp3Flag = 1;
      }
    }
  }

  useEffect(() => {
    if (seeMorePrimary === false) {
      var sliced = summary.slice(0, 250);
      var lastIndex = sliced.lastIndexOf(' ');

      setSliceSummary(sliced.slice(0, lastIndex));
    }

    if (seeMorePrimary === true) {
      setSliceSummary(summary);
    }
  }, [seeMorePrimary]);

  useEffect(() => {
    if (seeMorePrimary === false) {
      var sliced = editedSummary.slice(0, 250);
      var lastIndex = sliced.lastIndexOf(' ');
      setSliceSummary(sliced.slice(0, lastIndex));
    } else {
      setSliceSummary(editedSummary);
    }
  }, [editedSummary]);

  const triggerShowMore = () => {
    setShowMore(!showMore);
    // setShowMore(!showMore)
  };

  var flag = 0;
  for (var i = 0; i < data?.length; i++) {
    if (data[i]?.ClipUpdateStatusSummary !== '0' && data[i]?.ClipUpdateStatusSummary !== null) {
      flag = 1;
    }
  }
  const setSummaryInChild = analysisData => {
    setSummaryData(analysisData);
  };

  const handleCheckbox = index => {
    if (selectedItems.includes(index)) {
      const tempSelectItems = [...selectedItems];
      const filterItems = tempSelectItems.filter(item => item != index);
      setSelectedItems([...filterItems]);
    } else {
      setSelectedItems(prev => [...prev, index]);
    }
  };

  // console.warn('question data', data);
  useEffect(() => {}, [addReport]);

  useEffect(() => {}, [summaryData]);

  useEffect(() => {
    setSelectedGenerateSummary([...selectedGenerateSummary, preSelectedGenerateSummary]);
  }, [preSelectedGenerateSummary]);

  return (
    <div className="pb-4  question" style={{ borderBottom: '1px solid var(--greyBlue30)' }}>
      <div className="d-flex justify-content-between my-3">
        <div style={{ width: '75%' }}>
          <Heading
            fontSize="14px"
            fontWeight="700"
            color="var(--deepBlue)"
            className="mb-0 pb-0"
            breakHeading={false}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              {question}
              <span>
                {information && vType && vType.length > 0 && (
                  <Heading fontSize="12px" fontWeight="400" color="var(--deepBlue)" className="mb-0 pb-0 pl-1">
                    {data?.length + ' Videos'}
                  </Heading>
                )}

                {information && type === 'audio' && (
                  <Heading fontSize="12px" fontWeight="400" color="var(--deepBlue)" className="mb-0 pb-0 pl-1">
                    {data?.length + ' Audios'}
                  </Heading>
                )}
                {information && type === 'image' && (
                  <Heading fontSize="12px" fontWeight="400" color="var(--deepBlue)" className="mb-0 pb-0 pl-1">
                    {data?.length + ' Photos'}
                  </Heading>
                )}
                {information && vType && vType.length === 0 && (
                  <Heading fontSize="12px" fontWeight="400" color="var(--deepBlue)" className="mb-0 pb-0 pl-1">
                    {information}
                  </Heading>
                )}

                {/* {information && type === 'video_mp4' && (
                  <Heading fontSize="12px" fontWeight="400" color="var(--deepBlue)" className="mb-0 pb-0 pl-1">
                    {data?.length + ' Videos'}
                  </Heading>
                )} */}
              </span>

              {data && type == 'video' && isMp4 == false && information.split('')[0] !== '0' ? (
                <SummaryTextContainer
                  type={'video'}
                  interviewsCompleted={interviewsCompleted}
                  isLoading={isLoading}
                  summary={summary}
                  isMp4={false}
                  setIsLoadingView={setIsLoadingView}
                  isLoadingVideo={isLoadingVideo}
                  question_ID={qid}
                  id={data[data?.length - 1]?.projectRepondentVideo_ID}
                  project_id={id}
                  setSelectedGenerateSummary={setSelectedGenerateSummary}
                  preSelectedGenerateSummary={preSelectedGenerateSummary}
                  selectedGenerateSummary={selectedGenerateSummary}
                  setIsLoading={setIsLoading}
                  flag={flag}
                  isSummary={isSummary}
                  setSummary={setSummary}
                  setSliceSummary={setSliceSummary}
                />
              ) : (
                ' '
              )}

              {type == 'video' && isMp4 == true && information.split('')[0] !== '0' ? (
                <SummaryTextContainer
                  type={'video'}
                  mp3Flag={mp3Flag}
                  interviewsCompleted={interviewsCompleted}
                  isLoading={isLoading}
                  summary={summary}
                  isMp4={true}
                  setIsLoadingView={setIsLoadingView}
                  isLoadingVideo={isLoadingVideo}
                  question_ID={qid}
                  id={data ? data[data?.length - 1]?.projectRepondentVideo_ID : 0}
                  project_id={id}
                  setSelectedGenerateSummary={setSelectedGenerateSummary}
                  preSelectedGenerateSummary={preSelectedGenerateSummary}
                  selectedGenerateSummary={selectedGenerateSummary}
                  setIsLoading={setIsLoading}
                  flag={flag}
                  isSummary={isSummary}
                  setSummary={setSummary}
                  setSliceSummary={setSliceSummary}
                />
              ) : (
                ' '
              )}

              {type == 'audio' && information.split('')[0] !== '0' ? (
                <SummaryTextContainer
                  type={'audio'}
                  mp3Flag={mp3Flag}
                  interviewsCompleted={interviewsCompleted}
                  isLoading={isLoading}
                  summary={summary}
                  isMp4={false}
                  setIsLoadingView={setIsLoadingView}
                  isLoadingVideo={isLoadingVideo}
                  question_ID={qid}
                  id={data ? data[data?.length - 1]?.projectRepondentVideo_ID : 0}
                  project_id={id}
                  setSelectedGenerateSummary={setSelectedGenerateSummary}
                  preSelectedGenerateSummary={preSelectedGenerateSummary}
                  selectedGenerateSummary={selectedGenerateSummary}
                  setIsLoading={setIsLoading}
                  flag={flag}
                  isSummary={isSummary}
                  setSummary={setSummary}
                  setSliceSummary={setSliceSummary}
                />
              ) : (
                ' '
              )}

              {/* {type == 'video_mp4' && information.split('')[0] !== '0' ? (
                <SummaryTextContainer
                  type={'video_mp4'}
                  interviewsCompleted={interviewsCompleted}
                  isLoading={isLoading}
                  summary={summary}
                  setIsLoadingView={setIsLoadingView}
                  isLoadingVideo={isLoadingVideo}
                  question_ID={qid}
                  id={data[data?.length - 1]?.projectRepondentVideo_ID}
                  project_id={id}
                  setSelectedGenerateSummary={setSelectedGenerateSummary}
                  preSelectedGenerateSummary={preSelectedGenerateSummary}
                  selectedGenerateSummary={selectedGenerateSummary}
                  setIsLoading={setIsLoading}
                  flag={flag}
                  isSummary={isSummary}
                  setSummary={setSummary}
                  setSliceSummary={setSliceSummary}
                />
              ) : (
                ' '
              )} */}
            </div>

            {/* {console.log(summary)} */}
            {isLoadingVideo == true ? (
              <div style={{ display: 'inline' }} className="pb-0">
                <Heading fontSize="12px" fontWeight="500" color="var(--deepBlue)" className="mb-0 pb-0 mt-0">
                  Clips Loading...
                </Heading>
                <img src={loading} className="loading" alt="loading" />
              </div>
            ) : (
              ''
            )}

            {isLoadingAudio == true && type == 'audio' ? (
              <div style={{ display: 'inline' }} className="pb-0">
                <Heading fontSize="12px" fontWeight="500" color="var(--deepBlue)" className="mb-0 pb-0 mt-0">
                  Audio Notes Loading...
                </Heading>
                <img src={loading} className="loading" alt="loading" />
              </div>
            ) : (
              ''
            )}
          </Heading>
        </div>

        <div className="d-flex align-items-center">
          {selectedItems == false ? (
            <CustomButton
              fontWeight="400"
              fontSize="10px"
              className="ml-0"
              size="small"
              variant="outlined"
              buttonColor="var(--sapGreen)"
              disabled={true}
              onClick={() => handleCopyClick(qid, addReport, question, questionObject)}
            >
              {true ? <img src={copy} /> : <img src={copy_active} />} Copy to Report
            </CustomButton>
          ) : (
            <CustomButton
              fontWeight="400"
              fontSize="10px"
              className="ml-2"
              size="small"
              variant="outlined"
              buttonColor="var(--sapGreen)"
              disabled={false}
              onClick={() => handleCopyClick(qid, addReport, question, questionObject)}
            >
              {false ? <img src={copy} /> : <img src={copy_active} />} Copy to Report
            </CustomButton>
          )}
        </div>
      </div>
      {/* {isLoading === true ? <div className='pb-2'>
				<Heading
					fontSize='12px'
					fontWeight='500'
					color='var(--deepBlue)'
					className='mb-0 pb-0 mt-4'
				>
					Loading Summary...
				</Heading>
				<img src={loading} className="loading" alt="loading" />
			</div> : ""} */}

      {isLoading === false && summary && selectedGenerateSummary.includes(qid) ? (
        <div>
          <Heading
            fontSize="14px"
            fontWeight="700"
            fontFamily="Noto Sans"
            fontStyle="normal"
            lineHeight="normal"
            color="#38454F"
            className="mb-0 pb-2 mt-2"
          >
            <b>Summary</b>
          </Heading>

          <div
            style={{
              background: 'rgb(242, 244, 244)',
              marginBottom: '2%',
              padding: '1%',
              marginTop: '1 %',
              borderRadius: '5px'
            }}
          >
            {isEditing ? ( // Conditionally render textarea for editing
              <>
                <CustomTextArea
                  value={editedSummary}
                  onChange={e => setEditedSummary(e.target.value)}
                  rows={4}
                  showCharacters={false}
                  characterLimit={524288}
                  cols={50}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Icon onClick={handleSave} style={{ cursor: 'pointer' }}>
                    save
                  </Icon>
                </div>
              </>
            ) : (
              <>
                {summary.length > 250 ? sliceSummary : summary}
                {summary.length > 250 ? (
                  <SeeButton
                    style={{ display: 'inline-block' }}
                    className="mt-0"
                    simple
                    color="var(--primaryColor)"
                    fontSize="0.875rem"
                    fontWeight="500"
                    onClick={() => setSeeMorePrimary(!seeMorePrimary)}
                  >
                    {seeMorePrimary ? SeeLess() : SeeMore()}
                  </SeeButton>
                ) : (
                  ''
                )}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <ActionIcon
                    onClick={() => {
                      setIsEditing(true);
                      setEditedSummary(summary);
                    }}
                  >
                    create
                  </ActionIcon>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        ''
      )}

      {type === 'audio' && isLoadingAudio !== false ? (
        ''
      ) : (
        <GridContainer>
          {type == 'video'
            ? data?.map((item, index) => {
                let tagH = [];
                item?.tags?.map((a, i) => {
                  if (a.tag == 'Hits') {
                    tagH.push('Hits');
                  }
                  if (a.tag == 'Misses') {
                    tagH.push('Misses');
                  }
                });
                // if (item.name == 'peter') {
                //   console.log('YAAAY', item);
                //   // console.log('YAAAY', cTag, isLoadingVideo, vType);
                // }

                if (!cTag && isLoadingVideo == false && isMp4 == true && vType.length == 0) {
                  return (
                    <AnalysisVideo
                      tag={item?.tags
                        ?.reduce((f, k) => {
                          f.push(k.tag);
                          return f;
                        }, [])
                        .join(',')}
                      description={description}
                      note={item.VideoNote}
                      imgLink={item.preAssingedImageUrl}
                      // thumbLink={item.preAssingedImageUrl}
                      thumbLink={item.mp4ThumbnailFilePath}
                      ref={(el) => (videoRefs.current[index] = el)}
                      // isPlaying={playingVideo == index}
                      onPlay={(playerRef) => handlePlay(index, playerRef)}
                      keepCheckBox={true}
                      checkBoxValue={item.videoPresignedUrl}
                      selectCheckBox={val => {
                        let d = addReport;
                        if (d.hasOwnProperty(val.vidId.toString())) {
                          delete d[val.vidId.toString()];
                          if (Object.keys(d).length == 0) {
                            setSelectedItems(false);
                          }
                        } else {
                          if (Object.keys(addReport).length >= 8) {
                            console.log('Maximum 8 Videos can be exported.');
                            return;
                          }
                          (d[val.vidId.toString()] = val), setSelectedItems(true);
                        }

                        setAddReport({ ...d });
                      }}
                      name={item.name}
                      button={item.buttonName}
                       vidId={item.questionAnswerImage_ID}
                    // imgId={item.questionAnswerImage_ID}
                      qid={qid}
                      selection={addReport}
                      clipUpdateStatus={item.MP3UpdateSummaryStatus}
                    />
                  );
                }

                if (!cTag && isLoadingVideo == false && isMp4 == false && vType.length == 0) {
                  return (
                    <AnalysisVideo
                      tag={item?.tags
                        ?.reduce((f, k) => {
                          f.push(k.tag);
                          return f;
                        }, [])
                        .join(',')}
                      description={description}
                      note={item.VideoNote}
                      imgLink={item.videoPresignedUrl}
                      thumbLink={item.thumbnailPresignedUrl}
                      ref={(el) => (videoRefs.current[index] = el)}
                      // isPlaying={playingVideo == index}
                      onPlay={(playerRef) => handlePlay(index, playerRef)}
                      checkBoxValue={item.videoPresignedUrl}
                      selectCheckBox={val => {  
                        let d = addReport;
                        console.log('d', d);
                        if (d.hasOwnProperty(val.vidId.toString())) {
                          delete d[val.vidId.toString()];
                          if (Object.keys(d).length == 0) {
                            setSelectedItems(false);
                          }
                        } else {
                          (d[val.vidId.toString()] = val), setSelectedItems(true);
                        }

                        setAddReport({ ...d });
                      }}
                      name={item.name}
                      button={item.buttonName}
                      vidId={item.projectRepondentVideo_ID}
                      qid={qid}
                      selection={addReport}
                      clipUpdateStatus={item.ClipUpdateStatus}
                    />
                  );
                }

                if (cTag && isLoadingVideo == false && vType.length == 0) {
                  if (item?.tags?.indexOf(cTag) != -1) {
                    return (
                      <AnalysisVideo
                        tag={item?.tags
                          ?.reduce((f, k) => {
                            f.push(k.tag);
                            return f;
                          }, [])
                          .join(',')}
                        description={description}
                        // hoverList={item.tags}
                        note={item.VideoNote}
                        imgLink={item.videoPresignedUrl}
                        thumbLink={item.thumbnailPresignedUrl}
                        ref={(el) => (videoRefs.current[index] = el)}
                        // isPlaying={playingVideo == index}
                        onPlay={(playerRef) => handlePlay(index, playerRef)}
                        checkBoxValue={item.videoPresignedUrl}
                        selectCheckBox={val => {
                          let d = addReport;
                          if (d.hasOwnProperty(val.vidId.toString())) {
                            delete d[val.vidId.toString()];
                            if (Object.keys(d).length == 0) {
                              setSelectedItems(false);
                            }
                          } else {
                            (d[val.vidId.toString()] = val), setSelectedItems(true);
                          }

                          setAddReport({ ...d });
                        }}
                        name={item.name}
                        button={item.buttonName}
                        vidId={item.projectRepondentVideo_ID}
                        qid={qid}
                        selection={addReport}
                      />
                    );
                  }
                }

                if (!cTag && isLoadingVideo == false && vType.length > 0) {
                  if (vType.indexOf(item.buttonName) != -1 || tagH.some(val => vType.includes(val))) {
                    return (
                      <AnalysisVideo
                        tag={item.tags
                          .reduce((f, k) => {
                            f.push(k.tag);
                            return f;
                          }, [])
                          .join(',')}
                        description={description}
                        note={item.VideoNote}
                        imgLink={item.videoPresignedUrl}
                        thumbLink={item.thumbnailPresignedUrl}
                        ref={(el) => (videoRefs.current[index] = el)}
                        // isPlaying={playingVideo == index}
                        onPlay={(playerRef) => handlePlay(index, playerRef)}
                        checkBoxValue={item.videoPresignedUrl}
                        selectCheckBox={val => { 
                          let d = addReport;
                          if (d.hasOwnProperty(val.vidId.toString())) {
                            delete d[val.vidId.toString()];
                            if (Object.keys(d).length == 0) {
                              setSelectedItems(false);
                            }
                          } else {
                            (d[val.vidId.toString()] = val), setSelectedItems(true);
                          }

                          setAddReport({ ...d });
                        }}
                        name={item.name}
                        button={item.buttonName}
                        vidId={item.projectRepondentVideo_ID}
                        qid={qid}
                        selection={addReport}
                      />
                    );
                  }
                }

                if (cTag && isLoadingVideo == false && vType.length > 0) {
                  if (item.tags.indexOf(cTag) != -1 && vType.indexOf(item.buttonName) != -1) {
                    return (
                      <AnalysisVideo
                        tag={item.tags
                          .reduce((f, k) => {
                            f.push(k.tag);
                            return f;
                          }, [])
                          .join(',')}
                        description={description}
                        note={item.VideoNote}
                        imgLink={item.videoPresignedUrl}
                        thumbLink={item.thumbnailPresignedUrl}
                        ref={(el) => (videoRefs.current[index] = el)}
                        // isPlaying={playingVideo == index}
                        onPlay={(playerRef) => handlePlay(index, playerRef)}
                        checkBoxValue={item.videoPresignedUrl}
                        selectCheckBox={val => {
                          let d = addReport;
                          if (d.hasOwnProperty(val.vidId.toString())) {
                            delete d[val.vidId.toString()];
                            if (Object.keys(d).length == 0) {
                              setSelectedItems(false);
                            }
                          } else {
                            (d[val.vidId.toString()] = val), setSelectedItems(true);
                          }

                          setAddReport({ ...d });
                        }}
                        name={item.name}
                        button={item.buttonName}
                        vidId={item.projectRepondentVideo_ID}
                        qid={qid}
                        selection={addReport}
                      />
                    );
                  }
                }
              })
            : // : data.map((item, index) => (
              // 	<AnalysisAudio
              // 		key={index}
              // 		imgLink={item.preAssingedImageUrl}
              // 		checkBoxValue={item.preAssingedImageUrl}
              // 		selectCheckBox={(val) => {
              // 			let d = addReport
              // 			if (d.hasOwnProperty(val.imgId.toString())) {
              // 				delete d[val.imgId.toString()]
              // 				if (Object.keys(d).length == 0) {
              // 					setSelectedItems(false)
              // 				}
              // 			} else {
              // 				if (Object.keys(addReport).length >= 8) {
              // 					console.log("Maximum 8 photos can be exported.")
              // 					return
              // 				}
              // 				d[val.imgId.toString()] = val,
              // 					setSelectedItems(true)
              // 			}

              // 			setAddReport({ ...d })
              // 		}
              // 		}
              // 		name={item.name}
              // 		imgId={item.questionAnswerImage_ID}
              // 		qid={qid}
              // 		selection={addReport}

              // 	/>
              // ))
              data?.map((item, index) => {
                if (isLoadingAudio === false && type === 'audio') {
                  return (
                    <AnalysisAudio
                      key={index}
                      imgLink={item.preAssingedImageUrl}
                      checkBoxValue={item.preAssingedImageUrl}
                      selectCheckBox={val => {
                        let d = addReport;

                        if (d.hasOwnProperty(val.imgId.toString())) {
                          delete d[val.imgId.toString()];
                          if (Object.keys(d).length == 0) {
                            setSelectedItems(false);
                          }
                        } else {
                          if (Object.keys(addReport).length >= 8) {
                            console.log('Maximum 8 Audios can be exported.');
                            return;
                          }
                          (d[val.imgId.toString()] = val), setSelectedItems(true);
                        }

                        setAddReport({ ...d });
                      }}
                      name={item.name}
                      imgId={item.questionAnswerImage_ID}
                      qid={qid}
                      selection={addReport}
                    />
                  );
                }

                return (
                  <AnalysisImage
                    key={index}
                    imgLink={
                      item.preAssingedImageUrl
                    }
                    checkBoxValue={
                      item.preAssingedImageUrl
                    }
                    selectCheckBox={val => {
                      let d = addReport;
                      if (d.hasOwnProperty(val.imgId.toString())) {
                        delete d[val.imgId.toString()];
                        if (Object.keys(d).length == 0) {
                          setSelectedItems(false);
                        }
                      } else {
                        if (Object.keys(addReport).length >= 8) {
                          console.log('Maximum 8 photos can be exported.');
                          return;
                        }
                        (d[val.imgId.toString()] = val), setSelectedItems(true);
                      }

                      setAddReport({ ...d });
                    }}
                    name={item.name}
                    imgId={item.questionAnswerImage_ID}
                    qid={43051}
                    selection={addReport}
                  />
                );
              })}
        </GridContainer>
      )}
    </div>
  );
}

export default AnalysisQuestion;
